import axios from '@/api/index'

// 仓库类型
export const Canglei = params => {
  return axios.request({
    url: 'storehouse/type/list',
    method: 'get',
    params
  })
}

// 新建仓库  
export const Xinjian = data => {
  return axios.request({
    url: `storehouse/create`,
    method: 'post',
    data
  })
}
// 仓库列表  
export const Getcanglist = data => {
  return axios.request({
    url: `/storehouse/list`,
    method: 'post',
    data
  })
}
// 仓库修改   
export const Setcang = data => {
  return axios.request({
    url: `/storehouse/edit`,
    method: 'post',
    data
  })
}

// 货物类型  
export const Huowu = params => {
  return axios.request({
    url: '/storehouse/goods/types',
    method: 'get',
    params
  })
}

// 新建配件 
export const Xinpei = data => {
  return axios.request({
    url: `/storehouse/goods/create`,
    method: 'post',
    data
  })
}

// 配件列表  
export const Getpeilist = data => {
  return axios.request({
    url: `storehouse/goods/list`,
    method: 'post',
    data
  })
}

// 配件修改  
export const Endpei = data => {
  return axios.request({
    url: `/storehouse/goods/edit`,
    method: 'post',
    data
  })
}

// 禁用配件 
export const Jinpei = data => {
  return axios.request({
    url: `/storehouse/goods/close`,
    method: 'post',
    data
  })
}
// 启用配件 
export const Qipei = data => {
  return axios.request({
    url: `/storehouse/goods/open`,
    method: 'post',
    data
  })
}
// 禁用仓库 
export const Jincang = data => {
  return axios.request({
    url: `/storehouse/close`,
    method: 'post',
    data
  })
}
// 启用仓库
export const Qicang = data => {
  return axios.request({
    url: `/storehouse/open`,
    method: 'post',
    data
  })
}

// 新建货物类型
export const Xinxing = data => {
  return axios.request({
    url: `/storehouse/goods/type/create`,
    method: 'post',
    data
  })
}
// 货物类型树编辑
export const Endhuoshu = data => {
  return axios.request({
    url: `/storehouse/goods/type/edit`,
    method: 'post',
    data
  })
}

// 货物类型树  /storehouse/goods/type/tree
export const Huoshu = params => {
  return axios.request({
    url: '/storehouse/goods/type/tree',
    method: 'get',
    params
  })
}
// 商品搜索
export const Shopsou = params => {
  return axios.request({
    url: '/storehouse/goods/search',
    method: 'get',
    params
  })
}
// 仓库搜索
export const Cangsou = params => {
  return axios.request({
    url: '/storehouse/search',
    method: 'get',
    params
  })
}

// 申请采购  

export const Shencai = data => {
  return axios.request({
    url: `/storehouse/purchase/apply`,
    method: 'post',
    data
  })
}

// 审核人列表
export const Shenheren = params => {
  return axios.request({
    url: '/storehouse/auth/users',
    method: 'get',
    params
  })
}

// 采购申请列表  
export const Shenqinglist = data => {
  return axios.request({
    url: `/storehouse/purchase/apply/list`,
    method: 'post',
    data
  })
}
// 采购审核 
export const Shencaihe = data => {
  return axios.request({
    url: `/storehouse/purchase/apply/auth`,
    method: 'post',
    data
  })
}

// 代采购明细 
export const Daicai = data => {
  return axios.request({
    url: `/storehouse/purchase/need/items`,
    method: 'post',
    data
  })
}
// 创建采购单
export const Setdan = data => {
  return axios.request({
    url: `/storehouse/purchase/create`,
    method: 'post',
    data
  })
}
// 采购单列表
export const Caigoudanlist = data => {
  return axios.request({
    url: `/storehouse/purchase/list`,
    method: 'post',
    data
  })
}

// 采购单列表详情  
export const Xiangqing = params => {
  return axios.request({
    url: '/storehouse/purchase/info',
    method: 'get',
    params
  })
}
// 供应商搜索  
export const Gongyingsou = params => {
  return axios.request({
    url: '/storehouse/supplier/search',
    method: 'get',
    params
  })
}

// 仓库采购公司列表 
export const Canggongsi = params => {
  return axios.request({
    url: '/storehouse/purchase/company/list',
    method: 'get',
    params
  })
}

// 对公采购申请详情 
export const Shenxiang = params => {
  return axios.request({
    url: '/storehouse/purchase/apply/info',
    method: 'get',
    params
  })
}


// 详情采购合同提交 
export const Submit = data => {
  return axios.request({
    url: `/storehouse/purchase/contract/submit`,
    method: 'post',
    data
  })
}

// 采购单附属信息 
export const Caifushu = params => {
  return axios.request({
    url: '/storehouse/purchase/info/addition',
    method: 'get',
    params
  })
}

// 详情明细提交 
export const Xiangmingxi = data => {
  return axios.request({
    url: `/storehouse/purchase/item/submit`,
    method: 'post',
    data
  })
}

// 详情费用提交 
export const Xiangfeiyong = data => {
  return axios.request({
    url: `/storehouse/purchase/fee/other/submit`,
    method: 'post',
    data
  })
}

// 付款明细提交 
export const Xiangfukuan = data => {
  return axios.request({
    url: `/storehouse/purchase/pay/submit`,
    method: 'post',
    data
  })
}
// 回票明细提交   
export const huipiaotijiao = data => {
  return axios.request({
    url: `/storehouse/purchase/return/submit`,
    method: 'post',
    data
  })
}

//  采购单编辑
export const Endbiaoba = data => {
  return axios.request({
    url: `/storehouse/purchase/edit`,
    method: 'post',
    data
  })
}

// 货品批次详情 
export const Huopixiang = data => {
  return axios.request({
    url: `/storehouse/goods/batch/info`,
    method: 'post',
    data
  })
}

// 仓库预警列表 
export const Cangyulist = data => {
  return axios.request({
    url: `/storehouse/goods/house/warn/list`,
    method: 'post',
    data
  })
}

// 仓库预警值编辑 
export const Cangyuzhi = data => {
  return axios.request({
    url: `/storehouse/goods/house/warn/edit`,
    method: 'post',
    data
  })
}
// 后台出入库订单明细 
export const Houding = data => {
  return axios.request({
    url: `/storehouse/admin/order/item/list`,
    method: 'post',
    data
  })
}
// 后台批次列表 
export const Houtaipi = data => {
  return axios.request({
    url: `/storehouse/admin/batch/list`,
    method: 'post',
    data
  })
}

// 仓库选项列表 
export const Cangxuan = data => {
  return axios.request({
    url: `/storehouse/house/options`,
    method: 'post',
    data
  })
}

// 获取可以货品分组列表 
export const Huofen = data => {
  return axios.request({
    url: `/storehouse/goods/avail/list`,
    method: 'post',
    data
  })
}

// 申请配件调仓 
export const Shentiao = data => {
  return axios.request({
    url: `/storehouse/adjust/apply`,
    method: 'post',
    data
  })
}

// 配件调仓申请列表 
export const Shenpeilist = data => {
  return axios.request({
    url: `/storehouse/adjust/list`,
    method: 'post',
    data
  })
}

// 调出申请详情 
export const Daoshenxiang = data => {
  return axios.request({
    url: `/storehouse/adjust/info`,
    method: 'post',
    data
  })
}

// 待办调仓申请列表 
export const Daibantiao = data => {
  return axios.request({
    url: `/storehouse/adjust/wait/list`,
    method: 'post',
    data
  })
}

// 待办详情 
export const Daixiang = data => {
  return axios.request({
    url: `/storehouse/adjust/wait/info`,
    method: 'post',
    data
  })
}

// 配件调配审核 
export const Diaoshen = data => {
  return axios.request({
    url: `/storehouse/adjust/auth`,
    method: 'post',
    data
  })
}